<template>
  <div class="admin-progress-overview">
    <b-card>
      <!-- Title -->
      <b-card-title>Your website completion progress is
        {{ progress.toFixed(2) }}%</b-card-title>
      <b-card-sub-title>
        You completed
        <span class="font-weight-bold">{{ completedFieldsCount }}</span> out of
        <span class="font-weight-bold">{{ numberOfFields }}</span> items
      </b-card-sub-title>
      <!-- Progress Bar -->
      <b-progress :value="progress" :max="100" variant="success" striped></b-progress>
      <!-- Progress Steps -->
      <div class="progress-steps">
        <b-row>
          <b-col lg>
            <div class="section-title">Website Theme</div>
            <ul class="section-list">
              <li>
                <font-awesome-icon v-if="hasFilled('theme')" :icon="['fas', 'check-circle']" />
                <font-awesome-icon v-else :icon="['far', 'check-circle']" />
                <router-link to="theme">Templates <span class="asterisk">*</span></router-link>
              </li>
            </ul>
          </b-col>
          <b-col lg>
            <div class="section-title">Personal Information</div>
            <ul class="section-list">
              <li>
                <font-awesome-icon v-if="hasFilled('email')" :icon="['fas', 'check-circle']" />
                <font-awesome-icon v-else :icon="['far', 'check-circle']" />
                <router-link to="contact-email">Email <span class="asterisk">*</span></router-link>
              </li>
              <li>
                <font-awesome-icon v-if="hasFilled('name-position')" :icon="['fas', 'check-circle']" />
                <font-awesome-icon v-else :icon="['far', 'check-circle']" />
                <router-link to="name-position">Name &amp; Position
                  <span class="asterisk">*</span></router-link>
              </li>
            </ul>
          </b-col>
          <b-col lg>
            <div class="section-title">Website Content</div>
            <ul class="section-list">
              <li>
                <font-awesome-icon v-if="hasFilled('intro-text')" :icon="['fas', 'check-circle']" />
                <font-awesome-icon v-else :icon="['far', 'check-circle']" />
                <router-link to="intro-text">Intro Text</router-link>
              </li>
              <li>
                <font-awesome-icon v-if="hasFilled('open-content')" :icon="['fas', 'check-circle']" />
                <font-awesome-icon v-else :icon="['far', 'check-circle']" />
                <router-link to="open-content">Main Body</router-link>
              </li>
              <li>
                <font-awesome-icon v-if="hasFilled('feature-text')" :icon="['fas', 'check-circle']" />
                <font-awesome-icon v-else :icon="['far', 'check-circle']" />
                <router-link to="feature-text">Additional Content</router-link>
              </li>
              <li>
                <div v-if="hasFilled('media-block')">
                  <font-awesome-icon :icon="['fas', 'check-circle']" />
                  <router-link to="manage-media">Photos & Videos</router-link>
                </div>
                <div v-else>
                  <font-awesome-icon :icon="['far', 'check-circle']" />
                  <router-link to="add-media-block">Photos & Videos</router-link>
                </div>
              </li>
              <li>
                <font-awesome-icon v-if="hasFilled('social-links')" :icon="['fas', 'check-circle']" />
                <font-awesome-icon v-else :icon="['far', 'check-circle']" />
                <router-link to="social-links">Social Links</router-link>
              </li>
            </ul>
          </b-col>
          <b-col lg>
            <div class="section-title">Events</div>
            <ul class="section-list">
              <li>
                <div v-if="hasFilled('events')">
                  <font-awesome-icon :icon="['fas', 'check-circle']" />
                  <router-link to="manage-events">Manage Events</router-link>
                </div>
                <div v-else>
                  <font-awesome-icon :icon="['far', 'check-circle']" />
                  <router-link to="add-event">Create Events</router-link>
                </div>
              </li>
            </ul>
          </b-col>
          <b-col lg>
            <div class="section-title">Endorsements</div>
            <ul class="section-list">
              <li>
                <font-awesome-icon v-if="hasFilled('endorsement')" :icon="['fas', 'check-circle']" />
                <font-awesome-icon v-else :icon="['far', 'check-circle']" />
                <router-link to="add-endorsement">Non-Candidate Statement</router-link>
              </li>
              <li v-if="hasEndorsements">
                <font-awesome-icon v-if="hasFilled('featured-endorsement')" :icon="['fas', 'check-circle']" />
                <font-awesome-icon v-else :icon="['far', 'check-circle']" />
                <router-link to="manage-endorsements">Manage Endorsements</router-link>
              </li>
              <li v-if="hasEndorsements">
                <font-awesome-icon v-if="hasFilled('featured-endorsement')" :icon="['fas', 'check-circle']" />
                <font-awesome-icon v-else :icon="['far', 'check-circle']" />
                <router-link to="election-book">Election Book</router-link>
              </li>
            </ul>
          </b-col>
          <b-col lg v-show="candidateModel.isPetition">
            <div class="section-title">Petition for Candidacy</div>
            <ul class="section-list">
              <li>
                <font-awesome-icon v-if="hasFilled('petition')" :icon="['fas', 'check-circle']" />
                <font-awesome-icon v-else :icon="['far', 'check-circle']" />
                <router-link to="manage-petition">Manage Signatures</router-link>
              </li>
            </ul>
          </b-col>
        </b-row>
      </div>
      <!-- Required Field -->
      <div class="required-fields">
        <span class="asterisk">*</span> Indicates required field necessary to
        publish website
      </div>
    </b-card>
  </div>
</template>

<script>
import CandidateMixin from "@/mixins/candidate.mixin";
export default {
  data() {
    return {
      numberOfFields: 10,
      loading: false,
      errorMessage: null,
    };
  },
  mixins: [CandidateMixin],
  // mounted() {
  //   if (this.candidateModel.isPetition) this.numberOfFields = 9;
  //   this.loading = false;
  // },
  computed: {
    hasEndorsements() {
      return this.$store.state.candidates.candidate.manageEndorsementList.length > 0
    },
    currentUserId() {
      return this.$store.auth.user.id;
    },
    progress() {
      return (this.completedFieldsCount / this.numberOfFields) * 100;
    },
    completedFieldsCount() {
      let candidate = this.candidateModel;
      let count = 0;
      if (candidate.profileTheme > 0) count++;
      if (candidate.contactEmail !== null && candidate.contactEmail !== "")
        count++;
      if (
        candidate.name !== null &&
        candidate.name !== "" &&
        candidate.positionId > 0
      )
        count++;
      if (
        candidate.isPetition == false &&
        candidate.endorsementStatements.length > 0
      )
        count++;
      if (candidate.featureText !== null && candidate.featureText !== "")
        count++;
      if (candidate.introText !== null && candidate.introText !== "") count++;
      if (candidate.openContent !== null && candidate.openContent !== "")
        count++;
      if (
        candidate.socialLinks.facebookLink !== null ||
        candidate.socialLinks.twitterLink !== null ||
        candidate.socialLinks.instagramLink !== null ||
        candidate.socialLinks.linkedInLink !== null ||
        candidate.socialLinks.personalWebsiteLink !== null ||
        candidate.socialLinks.fawLink !== null
      )
        count++;
      if (candidate.events.length > 0) count++;
      if (candidate.medias.length > 0) count++;
      //if (candidate.headshotPhotoPath !== null && candidate.name !== "") count++;
      return count;
    },
  },
  methods: {
    hasFilled(field) {
      let result = false;
      switch (field) {
        case "theme":
          result = this.candidateModel.profileTheme > 0;
          break;
        case "email":
          result =
            this.candidateModel.contactEmail != null &&
            this.candidateModel.contactEmail !== "";
          break;
        case "name-position":
          result =
            this.candidateModel.name != null &&
            this.candidateModel.name !== "" &&
            this.candidateModel.positionId > 0;
          break;
        case "intro-text":
          result =
            this.candidateModel.introText != null &&
            this.candidateModel.introText !== "";
          break;
        case "open-content":
          result =
            this.candidateModel.openContent != null &&
            this.candidateModel.openContent !== "";
          break;
        case "feature-text":
          result =
            this.candidateModel.featureText != null &&
            this.candidateModel.featureText !== "";
          break;
        case "endorsement":
          result = this.candidateModel.endorsementStatements.length > 0;
          break;
        case "featured-endorsement":
          result = this.candidateModel.endorsementGroups.length > 0;
          break;
        case "media-block":
          result = this.candidateModel.medias.length > 0;
          break;
        case "social-links":
          result =
            this.candidateModel.socialLinks.facebookLink !== null ||
            this.candidateModel.socialLinks.twitterLink !== null ||
            this.candidateModel.socialLinks.instagramLink !== null ||
            this.candidateModel.socialLinks.linkedInLink !== null ||
            this.candidateModel.socialLinks.personalWebsiteLink !== null ||
            this.candidateModel.socialLinks.fawLink !== null;
          break;
        case "events":
          result = this.candidateModel.events.length > 0;
          break;
        case "petition":
          result = this.candidateModel.petitions.length > 0;
          break;
        default:
          result = false;
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";

.admin-progress-overview {
  .card {
    margin: 30px 0;

    .card-title {
      font-family: $montserrat;
      font-weight: 600;
      font-size: 20px;
    }

    .card-subtitle {
      font-weight: 400;
      font-size: 16px;
    }

    .progress {
      margin: 30px 0;
    }

    .progress-steps {
      .row>.col-lg {
        border-right: 1px solid $bordergray;

        @media (max-width: $lg-width) {
          & {
            border-right: 0;
            border-bottom: 1px solid $bordergray;
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }

      .row>.col-lg:last-child {
        border-right: 0;

        @media (max-width: $lg-width) {
          & {
            border-bottom: 0;
          }
        }
      }

      .section-title {
        font-weight: 600;
        margin-bottom: 10px;
      }

      .section-list {
        margin-left: 25px;
        font-size: 14px;

        li {
          position: relative;

          svg {
            left: -25px;
            top: 2px;
            position: absolute;
            text-align: center;
            width: 20px;
          }

          a {
            color: $darkgray;
          }

          [data-prefix="fas"].fa-check-circle {
            color: $green;
          }

          [data-prefix="far"].fa-check-circle {
            color: $mediumgray;
          }
        }
      }
    }

    .required-fields {
      display: inline-block;
      background-color: $lightgray;
      color: $mediumgray;
      font-size: 14px;
      border-radius: 5px;
      padding: 10px 15px;
      margin-top: 60px;
    }
  }
}
</style>
