<template>
  <div class="admin-wizard">
    <AdminHeader />
    <main>
      <b-container>
        <b-row>
          <b-col>
            <AdminLandingMsg />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <AdminLandingStats v-if="candidateModel.hasPublishedContent" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <AdminProgressOverview />
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
import AdminLandingMsg from "@/components/admin/LandingMsg.vue";
import AdminProgressOverview from "@/components/admin/ProgressOverview.vue";
import AdminLandingStats from "@/components/admin/LandingStats.vue";
import CandidateMixin from "@/mixins/candidate.mixin";

export default {
  name: "Dashboard",
  mixins: [CandidateMixin],
  data() {
    return {
      loading: false,
      errorMessage: null
    };
  },
  components: {
    AdminLandingMsg,
    AdminLandingStats,
    AdminProgressOverview
  },
  created() {
    this.loadCandidate();
  },
  methods: {
    loadCandidate: function() {
      this.candidateService
        .GetUserAsync(this.$route.params.id)
        .then(res => {
          this.$store.commit("candidates/SET_CANDIDATE", res.data);
        })
        .catch(e => {
          this.errorMessage = e;
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/admin/_base.scss";

.admin-wizard {
  .card {
    margin: 15px 0;

    .card-title {
      font-family: $montserrat;
      font-weight: 700 !important;
      font-size: 20px !important;
    }

    .card-subtitle {
      font-weight: 400;
      font-size: 16px;
    }
  }
}
</style>
